import { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@mui/material";
// cmp
import PagePanel from "../cmp/page-panel";
import SubPage from "../cmp/playground/sub-page";
import ActionBuilder from "../cmp/playground/action-builder";
import ActionResult from "../cmp/playground/action-result";
import ResponsiveLayout from "../cmp/responsive-layout";
import PlaygroundCard from "../cmp/playground/playground-card";
// services
import Gupport from "../services/gupport";
import { sortAlphabetically } from "../services/l10n";
// types
import type { WithTranslation } from "react-i18next";
import type { HandlerId } from "../types/roc-ws";
import type { ApiSchemasTable } from "../types/roc-table";

type Props = WithTranslation;

type State = {
	// ready: boolean;
	schemaId: any | null; // TODO
	actionJson: "{}"; // TODO
	activePage: "action-send" | "action-result"; // TODO
	actionLoading: boolean;
	actionResultPayload: any | undefined; // TODO
	actionResultError: any | undefined; // TODO
	handlerId: HandlerId | null; // TODO
	apiSchemas: ApiSchemasTable;
	rbacs: Array<any>; // TODO
	showDialog: boolean;
};

class GupportPlaygroundPage extends Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			// ready: Gupport.ready,
			schemaId: null,
			actionJson: "{}",
			activePage: "action-send",
			actionLoading: false,
			actionResultPayload: undefined,
			actionResultError: undefined,
			handlerId: null,
			apiSchemas: Gupport.apiSchemas,
			rbacs: this.sort(Gupport.apiSchemas),
			showDialog: false,
		};

		this.handleGupportReady = this.handleGupportReady.bind(this);
		this.handleActionChange = this.handleActionChange.bind(this);
		this.handleActionJsonChange = this.handleActionJsonChange.bind(this);
		this.handleOnFocus = this.handleOnFocus.bind(this);
		this.handleSend = this.handleSend.bind(this);
		this.handleBack = this.handleBack.bind(this);
		this.sort = this.sort.bind(this);
		this.openDialog = this.openDialog.bind(this);
		this.handleDialogClose = this.handleDialogClose.bind(this);
	}

	override componentDidMount() {
		Gupport.on("ready", this.handleGupportReady);
	}

	override componentWillUnmount() {
		Gupport.off("ready", this.handleGupportReady);
		if (this.state.handlerId) {
			Gupport.abort(this.state.handlerId);
		}
	}

	handleGupportReady() {
		this.setState({
			// ready: Gupport.ready,
			apiSchemas: Gupport.apiSchemas,
			rbacs: this.sort(Gupport.apiSchemas),
		});
	}

	sort(apiSchemas: ApiSchemasTable) {
		if (apiSchemas.length === 0) {
			return [];
		}

		const rbacs = [{
			name: "unknown",
			apis: [],
		}];

		apiSchemas.forEach((apiSchema) => {
			const apiRbac = apiSchema.data.rbac || "unknown";
			let foundRbac = rbacs.find((rbac) => (rbac.name === apiRbac));
			if (!foundRbac) {
				foundRbac = {
					name: apiRbac,
					apis: [],
				};
				rbacs.push(foundRbac);
			}
			foundRbac.apis.push(apiSchema);
		});

		this.sortRbac(rbacs);
		rbacs.forEach((rbac) => {
			this.sortApis(rbac.apis);
		});

		return rbacs;
	}

	sortRbac(rbacs) {
		rbacs.sort((a, b) => (sortAlphabetically(a.name.toLowerCase(), b.name.toLowerCase())));
	}

	sortApis(apiSchemas) {
		apiSchemas.sort((a, b) => (sortAlphabetically(a.data.label.toLowerCase(), b.data.label.toLowerCase())));
	}

	handleActionChange(schemaId, json) {
		this.setState({
			showDialog: false,
			schemaId: schemaId,
			actionJson: json,
			activePage: "action-send",
		});
	}

	handleActionJsonChange(event) {
		this.setState({
			actionJson: event.target.value,
		});
	}

	handleOnFocus(event) {
		if (this.state.actionJson === "{}") {
			event.target.setSelectionRange(1, 1);
		}
	}

	handleSend() {
		if (this.state.handlerId) {
			Gupport.abort(this.state.handlerId);
		}
		const cmd = JSON.parse(this.state.actionJson);
		const listenerId = Gupport.send(cmd, (error, msg) => {
			if (!error && msg.payload.status !== "ok") {
				error = new Error(JSON.stringify(msg.payload, null, 2));
			}
			this.setState({
				actionLoading: false,
				actionResultPayload: error ? undefined : msg.payload,
				actionResultError: error,
			});
		});

		this.setState({
			actionLoading: true,
			activePage: "action-result",
			handlerId: listenerId,
		});
	}

	handleBack(page) {
		this.setState({
			activePage: page,
		});
	}

	openDialog() {
		this.setState({
			showDialog: true,
		});
	}

	handleDialogClose() {
		this.setState({
			showDialog: false,
		});
	}

	override render() {
		const { t } = this.props;
		const actionSend = (
			<>
				<PlaygroundCard title={t("playground.json")}>
					<TextField
						autoFocus={true}
						placeholder="{}"
						fullWidth={true}
						multiline={true}
						minRows={5}
						value={this.state.actionJson}
						onChange={this.handleActionJsonChange}
						onFocus={this.handleOnFocus}
					/>
				</PlaygroundCard>
				<div>
					<Button
						color="neutral"
						style={{ float: "left" }}
						onClick={this.openDialog}
					>
						{t("playground.templates")}
					</Button>
					<Button
						disabled={!this.state.schemaId}
						style={{ float: "right" }}
						onClick={this.handleSend}
					>
						{t("messages.send")}
					</Button>
				</div>
				<Dialog
					fullWidth={true}
					open={this.state.showDialog}
					onClose={this.handleDialogClose}
				>
					<DialogTitle>{t("playground.templates")}</DialogTitle>
					<DialogContent>
						<ActionBuilder
							schemas={this.state.apiSchemas}
							rbacs={this.state.rbacs}
							onChange={this.handleActionChange}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleDialogClose}>{t("dialog.ok")}</Button>
					</DialogActions>
				</Dialog>
			</>
		);
		const actionDetails = (
			<PlaygroundCard title={t("playground.details")}>
				<ActionResult
					loading={this.state.actionLoading}
					error={this.state.actionResultError}
					payload={this.state.actionResultPayload}
				/>
			</PlaygroundCard>
		);

		return (
			<ResponsiveLayout>
				<PagePanel screen="xs" activePage={this.state.activePage} title={t("playground.titleGupport")} activeRoute="/playground/gupport">
					<SubPage width="100%" page="action-send">
						{actionSend}
					</SubPage>
					<SubPage width="100%" page="action-result" onBack={this.handleBack.bind(this, "action-send")}>
						{actionDetails}
					</SubPage>
				</PagePanel>
				<PagePanel screen="sm" title={t("playground.titleGupport")} activeRoute="/playground/gupport">
					<SubPage width="33%">
						{actionSend}
					</SubPage>
					<SubPage width="67%">
						{actionDetails}
					</SubPage>
				</PagePanel>
				<PagePanel screen="lg" title={t("playground.titleGupport")} activeRoute="/playground/gupport" navigationDrawerDocked={true}>
					<SubPage width="33%">
						{actionSend}
					</SubPage>
					<SubPage width="67%">
						{actionDetails}
					</SubPage>
				</PagePanel>
			</ResponsiveLayout>
		);
	}

}

GupportPlaygroundPage.propTypes = {
	t: PropTypes.func.isRequired,
};

export default withTranslation()(GupportPlaygroundPage);
