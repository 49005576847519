import { useState, useEffect, Suspense, lazy } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Select, MenuItem, ListItemText, Skeleton } from "@mui/material";
// cmp
import ResponsivePagePanel from "../cmp/responsive-page-panel";
// contexts
import { useUserData } from "../context/UserDataContext";
// services
import Gupport from "../services/gupport";
import Responsive from "../services/responsive";
// types
import type { SelectChangeEvent } from "@mui/material/Select";
import type { CmdGetDeviceStats, CmdGetGatewayStats, CmdGetTable } from "../types/gupport";

// lazy
const Bar = lazy(() => import("../cmp/charts/bar-chart"));
const Pie = lazy(() => import("../cmp/charts/pie-chart"));

const WelcomePage = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { user } = useUserData();

	// const [ready, setReady] = useState(false);
	// const [loading, setLoading] = useState(true);
	const [container, setContainer] = useState(false);
	const [deviceStatData, setDeviceStatData] = useState<object | null>(null);
	const [selectedDeviceStat, setSelectedDeviceStat] = useState<object | null>(null);
	const [tableData, setTableData] = useState<Array<any> | null>(null);
	const [gatewayStatData, setGatewayStatData] = useState<object | null>(null);
	const [selectedGatewayStat, setSelectedGatewayStat] = useState<object | null>(null);
	// const [gatewayStatLoaded, setGatewayStatLoaded] = useState(false);
	const [getTableLoaded, setGetTableLoaded] = useState(false);

	useEffect(() => {
		const handleGupportReady = () => {
			// setReady(true);
		};

		const handleScreenChanged = () => {
			const gridScreenCols = {
				md: true,
				sm: false,
				xs: false,
			};

			setContainer(Boolean(Responsive.getScreenSizes().find((screen) => (gridScreenCols[screen])) ?? gridScreenCols.xs));
		};

		const getTable = () => {
			const cmd = {
				action: "getTable",
				tableName: "rocid_dict",
			} as const satisfies CmdGetTable;
			Gupport.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					setGetTableLoaded(true);
					setTableData(msg.payload.data);
				}
			});
		};

		const setSelectedDevice = () => {
			if (selectedGatewayStat && deviceStatData) {
				const selectedBucket = deviceStatData.channel.buckets.find((bucket) => (bucket.val === selectedGatewayStat.val));
				if (selectedBucket) {
					setSelectedDeviceStat(selectedBucket);
				}
			}
		};

		const getGatewayStats = () => {
			const cmd = {
				action: "getGatewayStats",
			} as const satisfies CmdGetGatewayStats;
			Gupport.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					const gatewayStat = msg.payload.data;
					const buckets = gatewayStat.channel.buckets;

					// setGatewayStatLoaded(true);
					setGatewayStatData(gatewayStat);
					setSelectedGatewayStat(buckets.length > 0 ? buckets[0] : null);
					setSelectedDevice();
				}
				// setLoading(false);
			});
		};

		const getDeviceStats = () => {
			const cmd = {
				action: "getDeviceStats",
			} as const satisfies CmdGetDeviceStats;
			Gupport.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					setDeviceStatData(msg.payload.data);
					setSelectedDeviceStat(null);
					if (selectedGatewayStat) {
						setSelectedDevice();
					}
				}
				// setLoading(false);
			});
		};

		const fetchStats = () => {
			if (Gupport.ready) {
				if (Gupport.getGatewayStats) {
					getTable();
					getGatewayStats();
					getDeviceStats();
				} else {
					navigate("/gateways");
				}
			} else {
				Gupport.once("ready", () => {
					if (Gupport.getGatewayStats) {
						getTable();
						getGatewayStats();
						getDeviceStats();
					} else {
						navigate("/gateways");
					}
				});
			}
		};

		handleScreenChanged();
		Responsive.on("screenChanged", handleScreenChanged);
		Gupport.on("ready", handleGupportReady);

		fetchStats();

		return () => {
			Responsive.off("screenChanged", handleScreenChanged);
			Gupport.off("ready", handleGupportReady);
		};
	}, []);

	// const handleOpenApplication = (route) => {
	// 	navigate(route);
	// };

	const handleSelect = (event: SelectChangeEvent) => {
		const currentSelectedGatewayStat = gatewayStatData.channel.buckets.find((bucket) => (bucket.val === event.target.value)) || null;
		const currentSelectedDeviceStat = deviceStatData.channel.buckets.find((bucket) => (bucket.val === event.target.value)) || null;

		setSelectedGatewayStat(currentSelectedGatewayStat);
		setSelectedDeviceStat(currentSelectedDeviceStat);
	};

	const getDataDefault = () => ({
		datasets: [{
			data: [],
			backgroundColor: [],
			label: t("welcome.gatewayStateTitle"), // for legend
		}],
		labels: [],
	});

	const getStyle = () => {
		if (container) {
			return {
				width: "50%",
				float: "left",
			};
		}
		return {
			width: "100%",
		};
	};

	const renderGatewayStat = () => {
		if (!selectedGatewayStat) {
			return null;
		}

		const data = getDataDefault();
		selectedGatewayStat.socket_status.buckets.forEach((bucket) => {
			data.datasets[0].data.push(bucket.count);
			const color = bucket.val === "live" ? "#27ae60" : "#e74c3c";
			data.datasets[0].backgroundColor.push(color);
			data.labels.push(bucket.val);
		});

		return (
			<section style={getStyle()}>
				<h3>{t("welcome.gatewayStateTitle")}</h3>
				<Suspense fallback={<Skeleton animation="wave" variant="rectangular" height={43} />}>
					<Pie width={100} height={70} data={data} />
				</Suspense>
			</section>
		);
	};

	const findColor = (rocId) => {
		if (tableData) {
			const data = tableData.find((data) => (data.id.string_key === rocId));
			if (data) {
				const color = data.data.color;
				return color.length === 9 ? color.substring(0, 7) : color;
			}
		}
	};

	const renderDeviceStat = () => {
		if (!selectedDeviceStat) {
			return null;
		}

		const options = {
			legend: {
				display: false,
			},
		};
		const data = getDataDefault();
		if (getTableLoaded && selectedDeviceStat.rocid) {
			selectedDeviceStat.rocid.buckets.forEach((bucket) => {
				data.datasets[0].data.push(bucket.count);
				data.datasets[0].backgroundColor.push(findColor(bucket.val));
				data.labels.push(bucket.val);
			});
		}
		data.datasets[0].label = t("welcome.deviceChartTT");

		return (
			<div style={getStyle()}>
				<h3>{t("welcome.deviceStatTitle")}</h3>
				<Suspense fallback={<Skeleton animation="wave" variant="rectangular" height={43} />}>
					<Bar width={100} height={70} options={options} data={data} />
				</Suspense>
			</div>
		);
	};

	return (
		<ResponsivePagePanel title={`Welcome ${(user?.user_name ?? "Mr. Anonymous")}`} activeRoute="/welcome">
			<div className="page-header-padding">
				{(selectedGatewayStat && gatewayStatData.channel.buckets.length > 1) &&
					<Select
						name="channel"
						value={selectedGatewayStat.val}
						style={{ width: "45%" }}
						onChange={handleSelect}
					>
						{
							gatewayStatData.channel.buckets.map((bucket, index) => (
								<MenuItem key={index} value={bucket.val}>
									<ListItemText primary={bucket.val} />
								</MenuItem>
							))
						}
					</Select>
				}
				<div>
					{renderGatewayStat()}
					{renderDeviceStat()}
					<br style={{ clear: "both" }} />
				</div>
			</div>
			{/* <Paper component="article">
				<iframe
					src={`${gupportWsUrl.replace("wss://", "https://")}/stats/consoles/glient.html`}
					width="100%"
					height="100%"
					style={{ display: "block", border: "none", height: "calc(100vh - 85px)" }}
				/>
			 /> */}
		</ResponsivePagePanel>
	);
};

export default WelcomePage;
