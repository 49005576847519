import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// cmp
import ResponsivePagePanel from "../cmp/responsive-page-panel";
import LogsTabs from "../cmp/logs/LogsTabs";
import Svg from "../cmp/svg";
// services
import Log from "../services/log";

type Props = {
	separatePage?: boolean;
	hideAppBar?: boolean;
};

const LogPage = ({ separatePage = false, hideAppBar = false }: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	return (
		<ResponsivePagePanel
			activeRoute="/logs"
			title={t("logs.title")}
			hideNavigationDrawer={separatePage}
			hideNavigationMenu={separatePage}
			hideAppBar={hideAppBar}
			iconElementRight={hideAppBar ? null :
				<IconButton onClick={() => (Log.openLogWindow())}>
					<Svg src="navigation/openNewWindow.svg" color={theme.palette.primary.contrastText} />
				</IconButton>
			}
		>
			<LogsTabs />
		</ResponsivePagePanel>
	);
};

export default LogPage;
