import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
// cmps
import ResponsivePagePanel from "../cmp/responsive-page-panel";
import BackupTabs from "../cmp/backup/BackupTabs";
// contexts
import { useUserData } from "../context/UserDataContext";

type Props = {
	hideAppBar?: boolean;
};

const BackPage = ({ hideAppBar = false }: Props) => {
	const { t } = useTranslation();

	const { ready } = useUserData();

	return (
		<ResponsivePagePanel title={t("backup.title")} activeRoute="/backups" hideAppBar={hideAppBar}>
			{ready ? <BackupTabs /> : <CircularProgress />}
		</ResponsivePagePanel>
	);
};

export default BackPage;
