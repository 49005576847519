import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
// cmps
import ResponsivePagePanel from "../cmp/responsive-page-panel";
import ServerOptions from "../cmp/ServerOptions";
// import Glient from "../services/glient";

const SettingsPage = () => {
	const { t } = useTranslation();

	return (
		<ResponsivePagePanel title={t("settings.title")} activeRoute="/settings">
			<Typography>{t("settings.title")}</Typography>
			<ServerOptions />
		</ResponsivePagePanel>
	);
};

export default SettingsPage;
