import { useEffect } from "react";
import { Paper } from "@mui/material";
// services
import User from "../services/user";

const LoginPage = () => {
	useEffect(() => {
		User.login();
	}, []);

	return (
		<div style={{display: "flex", height: window.innerHeight}}>
			<Paper style={{margin: "auto", padding: "16px", width: "300px"}}>
				Redirecting...
			</Paper>
		</div>
	);
};

export default LoginPage;
