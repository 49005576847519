import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
// cmps
import ResponsivePagePanel from "../cmp/responsive-page-panel";
import TEditorSelector from "../cmp/table-editor/teditor-selector";
// contexts
import { useUserData } from "../context/UserDataContext";

type Props = {
	hideAppBar?: boolean;
};

const TEditorPage = ({ hideAppBar = false }: Props) => {
	const { t } = useTranslation();

	const { ready } = useUserData();

	return (
		<ResponsivePagePanel title={t("teditor.title")} activeRoute="/teditor" hideAppBar={hideAppBar}>
			{ready ? <TEditorSelector /> : <CircularProgress />}
		</ResponsivePagePanel>
	);
};

export default TEditorPage;
