import { useTranslation } from "react-i18next";
// cmp
import ResponsivePagePanel from "../cmp/responsive-page-panel";
import GLogTable from "../cmp/logs/glog-table";

type Props = {
	hideAppBar?: boolean;
};

const GLogPage = ({ hideAppBar = false }: Props) => {
	const { t } = useTranslation();

	return (
		<ResponsivePagePanel title={t("glogs.title")} activeRoute="/glogs" hideAppBar={hideAppBar}>
			<GLogTable />
		</ResponsivePagePanel>
	);
};

export default GLogPage;
