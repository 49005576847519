import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
	Paper,
	Table,
	TableBody,
	TableHead,
	TableCell,
	TableRow,
	List,
	ListItem,
	ListItemText,
	Typography,
	Skeleton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
// cmp
import ResponsivePagePanel from "../cmp/responsive-page-panel";
// contexts
import { useServerOption } from "../context/ServerOptionContext";
// services
import Gupport from "../services/gupport";
// types
import type { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import type { CmdGetStats } from "../types/gupport";

interface GerverStats {
	nodes: Array<string>;
	running_nodes: Array<string>;
	tables: Record<string, GerverStatsTable>;
}

interface GerverStatsTable {
	count: number;
	ram: number;
}

interface Row {
	table: string;
	count: number;
	ram: number;
}

const StatsGerverPage = () => {
	const { t } = useTranslation();

	const serverOption = useServerOption();
	const [gerverStats, setGerverStats] = useState<GerverStats | null>(null);
	const [loadingGerverStats, setLoadingGerverStats] = useState(false);

	useEffect(() => {
		const handleGerverStats = (msg) => {
			if (msg.payload?.info === "getStats") {
				setGerverStats(msg.payload.stats);
				setLoadingGerverStats(false);
			}
		};

		const handleGupportReady = () => {
			setLoadingGerverStats(true);

			Gupport.on("message-rx", handleGerverStats);
			// ask for stats
			if (Gupport.getStats) {
				const cmd = {
					action: "getStats",
				} as const satisfies CmdGetStats;
				Gupport.send(cmd);
			}
		};

		setLoadingGerverStats(true);
		if (Gupport.ready) {
			handleGupportReady();
		} else {
			Gupport.once("ready", handleGupportReady);
		}

		return () => {
			Gupport.off("ready", handleGupportReady);
			Gupport.off("message-rx", handleGerverStats);
		};
	}, []);

	const columns = useMemo(() => ([
		{
			field: "table",
			headerName: t("statistics.table"),
			flex: 3,
		},
		{
			field: "count",
			headerName: t("statistics.count"),
			flex: 1,
		},
		{
			field: "ram",
			headerName: t("statistics.ram"),
			flex: 1,
		},
	] as const satisfies ReadonlyArray<GridColDef<Row>>), [t]);

	const rows: GridRowsProp<Row> = (gerverStats === null) ? [] : Object.keys(gerverStats.tables).map((table) => ({
		table: table,
		...gerverStats.tables[table],
	} as Row));

	return (
		<ResponsivePagePanel title={t("statistics.title")} activeRoute="/stats/gerver">
			<div className="page-header-padding">
				<section>
					<h2>{t("statistics.cluster")}</h2>
					<Paper>
						<List disablePadding={true}>
							<ListItem>
								<ListItemText primary={`${t("statistics.connectedTo")}: ${serverOption.label}`} />
							</ListItem>
							<ListItem>
								<ListItemText primary={`${t("statistics.clusterURL")}: ${serverOption.gupportWsUrl}`} />
							</ListItem>
							<ListItem>
								<ListItemText primary={`${t("statistics.glientURL")}: ${serverOption.glientWsUrl}`} />
							</ListItem>
						</List>
					</Paper>
				</section>
				<section>
					<h2>{t("statistics.gerverStats")}</h2>
					<Paper style={{ marginBottom: 20, overflow: "hidden" }}>
						<Typography variant="h6">{t("statistics.cNodes")}</Typography>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>{t("statistics.node")}</TableCell>
									<TableCell>{t("statistics.running")}</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{(loadingGerverStats || gerverStats === null) ?
									<TableRow className="last-row-no-border">
										<TableCell><Skeleton /></TableCell>
										<TableCell><Skeleton /></TableCell>
									</TableRow>
									:
									gerverStats.nodes.map((node) => (
										<TableRow key={node} className="last-row-no-border">
											<TableCell>{node}</TableCell>
											<TableCell>{gerverStats.running_nodes.includes(node) ? "running" : "not running"}</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
					</Paper>
					<Paper>
						<Typography variant="h6">{t("statistics.cTables")}</Typography>
						<DataGrid
							loading={loadingGerverStats}
							columns={columns}
							rows={rows}
							getRowId={(row) => (row.table)}
						/>
					</Paper>
				</section>
			</div>
		</ResponsivePagePanel>
	);
};

export default StatsGerverPage;
